<template>
  <div class="row m-0 align-items-center border p-3 rounded">
    <div class="col-12 col-md-8">
      <label :for="id">{{ label }}</label>
      <i
        v-if="helpId"
        class="bi bi-question-circle cursor-pointer"
        @click="$emit('show-help', helpId)"
      ></i>
      <input
        type="file"
        @change="handleFileUpload"
        class="form-control"
        :id="id"
        :accept="accept"
        multiple
      />
      <small class="form-text text-muted">
        {{
          files.length
            ? `${files.length} archivo(s) seleccionado(s)`
            : noFileMessage
        }}
      </small>
    </div>

    <div class="col-12 mt-3">
      <ul class="list-group">
        <li
          v-for="(file, index) in files"
          :key="index"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <div>
            <a
              v-if="file.file"
              :href="file.file"
              target="_blank"
              rel="noopener noreferrer"
              class="text-decoration-none"
            >
              {{ file.name }}
            </a>
            <span v-else>{{ file.name }}</span>
            <span v-if="file.extension === 'pdf'" class="badge bg-danger ms-2">
              PDF
            </span>
          </div>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click.stop="removeFile(file, index)"
          >
            Eliminar
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Swal from "sweetalert2";
import { nextTick } from "vue";
import fileManagerService from "@/services/api/fileManagerService";

export default {
  props: {
    label: { type: String, required: true },
    id: { type: String, required: true },
    required: { type: Boolean, default: false },
    noFileMessage: {
      type: String,
      default: "No se ha seleccionado ningún archivo",
    },
    accept: { type: String, default: "" },
    helpId: { type: String, default: null },
    initialFiles: { type: Array, default: () => [] },
  },
  emits: ["file-change", "file-removed"],
  setup(props, { emit }) {
    const files = ref([...props.initialFiles]);

    watch(
      () => props.initialFiles,
      (newFiles) => {
        files.value = [...newFiles];
      },
      { immediate: true }
    );

    const handleFileUpload = async (event) => {
      const selectedFiles = Array.from(event.target.files);
      const validFiles = filterValidFiles(selectedFiles, props.accept);

      if (validFiles.length !== selectedFiles.length) {
        showErrorAlert(
          "Archivos no permitidos",
          `Algunos archivos no cumplen con los tipos permitidos: ${props.accept}.`
        );
        return;
      }

      await uploadFiles(validFiles);
      emit("file-change", files.value);
      event.target.value = "";
    };

    const removeFile = async (file, index) => {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: `El archivo "${file.name}" será eliminado permanentemente.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await fileManagerService.deleteFile(file.file);
          files.value.splice(index, 1);

          nextTick(() => emit("file-removed", file));

          Swal.fire({
            icon: "success",
            title: "Archivo eliminado",
            text: `El archivo "${file.name}" ha sido eliminado exitosamente.`,
          });
        } catch (error) {
          console.error("Error al eliminar archivo:", error);

          Swal.fire({
            icon: "error",
            title: "Error al eliminar archivo",
            text: `No se pudo eliminar el archivo "${file.name}".`,
            confirmButtonText: "Aceptar",
          });
        }
      }
    };

    const filterValidFiles = (selectedFiles, accept) => {
      const allowedTypes = accept.split(",").map((type) => type.trim());
      return selectedFiles.filter((file) =>
        allowedTypes.some((type) =>
          type === "image/*"
            ? file.type.startsWith("image/")
            : file.type === type
        )
      );
    };

    const uploadFiles = async (validFiles) => {
      for (const file of validFiles) {
        try {
          const response = await fileManagerService.uploadFile(file);
          const { name, file: filePath, extension } = response.data;

          files.value.push({
            file: filePath,
            name,
            extension,
          });
        } catch (error) {
          showErrorAlert(
            "Error al subir archivo",
            `No se pudo subir el archivo ${file.name}.`
          );
        }
      }
    };

    const showErrorAlert = (title, text) => {
      Swal.fire({ icon: "error", title, text, confirmButtonText: "Aceptar" });
    };

    return {
      files,
      handleFileUpload,
      removeFile,
    };
  },
};
</script>

<style scoped>
.cursor-pointer {
  margin-left: 5px;
  cursor: pointer;
}
.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-group {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
}
</style>
