import { toRaw } from "vue";

export function buildFormData(form, linkType) {
  const formData = new FormData();

  Object.entries(form).forEach(([key, value]) => {
    switch (key) {
      case "image":
      case "icon":
        if (value instanceof File) {
          formData.append(key, value);
        }
        break;

      case "file":
        if (linkType === "pdf" && (value instanceof File || !value)) {
          formData.append(key, value);
        } else {
          formData.append(key, "");
        }
        break;

        case "files":
          if (Array.isArray(value)) {
            const rawFiles = toRaw(value);
        
            rawFiles.forEach((file, index) => {
        
              Object.entries(file).forEach(([propKey, propValue]) => {
                formData.append(`files[${index}][${propKey}]`, propValue);
              });
            });
          }
          break;
        
      case "imagePreview":
      case "iconPreview":
        break;

      default:
        formData.append(key, value);
        break;
    }
  });

  return formData;
}