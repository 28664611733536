<template>
  <div class="toggle-field-container">
    <label :for="id" class="form-label">
      {{ label }}
      <slot name="info"></slot>
    </label>
    <div class="mb-3 d-flex align-items-center toggle-switch-container">
      <input type="radio" :id="`${id}-yes`" v-model="show" :value="true" />
      <input type="radio" :id="`${id}-no`" v-model="show" :value="false" />
      <div class="switch">
        <label :for="`${id}-yes`">Sí</label>
        <label :for="`${id}-no`">No</label>
        <span></span>
      </div>
    </div>

    <transition name="fade">
      <div class="mb-3" v-if="show">
        <component
          :is="type === 'textarea' ? 'textarea' : 'input'"
          :value="internalValue"
          class="form-control"
          :id="id"
          :type="type === 'input' ? 'text' : undefined"
          @input="updateValue"
        />
      </div>
    </transition>
  </div>
</template>



<script>
import { ref, watch } from "vue";

export default {
  props: {
    label: { type: String, required: true },
    type: { type: String, default: "input" },
    modelValue: { required: true },
    id: { type: String, required: true },
  },
  setup(props, { emit }) {
    const show = ref(!!props.modelValue);
    const internalValue = ref(props.modelValue);

    const updateValue = (event) => {
      internalValue.value = event.target.value; 
      emit("update:modelValue", internalValue.value);
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue;
        show.value = !!newValue; 
      },
      { immediate: true }
    );

    watch(show, (newShow) => {
      if (!newShow) {
        internalValue.value = null; 
        emit("update:modelValue", null);
      }
    });

    return { show, internalValue, updateValue };
  },
};
</script>

<style scoped>
.toggle-field-container {
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.toggle-switch-container {
  align-items: center;
  display: flex;
}

.toggle-label {
  min-width: 150px; 
  text-align: left; 
  font-weight: bold;
  color: #555; 
  margin-right: 1rem;
}

.switch {
  position: relative;
  width: 118px;
  height: 31px;
  text-align: center;
  background: #4cd964;
  transition: background 0.3s ease;
  border-radius: 25px;
}

.switch span {
  position: absolute;
  width: 20px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin: -2px 0 0 -4px;
  background: white;
  transform: rotate(-45deg);
  border-radius: 2px;
  transition: all 0.2s ease;
}

.switch span:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 12px;
  margin-top: -8px;
  background: white;
  border-radius: 2px;
  transition: all 0.2s ease;
}

input[type="radio"] {
  display: none;
}

.switch label {
  cursor: pointer;
  width: 58px;
  line-height: 37px;
  text-align: center;
  font-weight: bold;
  transition: color 0.3s ease;
    font-size: 10px;
}

label[for$="-yes"] {
  position: absolute;
  left: 0;
  color: rgba(0, 0, 0, 0.3); 
}

label[for$="-no"] {
  position: absolute;
  right: 0;
  color: rgba(0, 0, 0, 0.3);
}

input[type="radio"]:checked[id$="-no"] ~ .switch {
  background: #ff3b30;
}

input[type="radio"]:checked[id$="-no"] ~ .switch span {
  margin-left: -8px;
  background: white;
}

input[type="radio"]:checked[id$="-no"] ~ .switch span:after {
  background: white;
  height: 20px;
  margin-left: 8px;
}

input[type="radio"]:checked[id$="-yes"] ~ .switch label[for$="-yes"],
input[type="radio"]:checked[id$="-no"] ~ .switch label[for$="-no"] {
  color: white;
}

input[type="radio"]:checked[id$="-yes"] ~ .switch label[for$="-no"],
input[type="radio"]:checked[id$="-no"] ~ .switch label[for$="-yes"] {
  color: rgba(255, 255, 255, 0.6);
}
</style>
