import { ref } from "vue";

export const useFormValidation = () => {
  const formErrors = ref({});

  const validateNumericField = (fieldName, fieldValue, fieldLabel) => {
    const numericRegex = /^[0-9]*$/;
    if (!fieldValue) {
      formErrors.value[fieldName] = `${fieldLabel} es obligatorio.`;
      return "";
    } else if (!numericRegex.test(fieldValue)) {
      formErrors.value[fieldName] = `${fieldLabel} debe contener solo números.`;
      return fieldValue.replace(/\D/g, "");
    } else {
      delete formErrors.value[fieldName];
      return fieldValue;
    }
  };
  

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      formErrors.value.email = "El correo electrónico es obligatorio.";
      return false;
    } else if (!emailRegex.test(email)) {
      formErrors.value.email = "Formato de correo electrónico inválido.";
      return false;
    } else {
      formErrors.value.email = "";
      return true;
    }
  };

  const validateConcessionaireForm = (formData) => {
    Object.keys(formErrors.value).forEach((key) => delete formErrors.value[key]);

    if (!formData.business_name) {
      formErrors.value.business_name = "La razón social es obligatoria.";
    }
    if (!formData.province_id) {
      formErrors.value.province_id = "La provincia es obligatoria.";
    } else {
      delete formErrors.value.province_id;
    }
  
    if (!formData.locality_id) {
      formErrors.value.locality_id = "La localidad es obligatoria.";
    } else {
      delete formErrors.value.locality_id;
    }
  
    if (!formData.regional_id) {
      formErrors.value.regional_id = "La regional es obligatoria.";
    } else {
      delete formErrors.value.regional_id;
    }
    if (!formData.street_name) {
      formErrors.value.street_name = "La dirección es obligatoria.";
    }

    validateNumericField("street_number", formData.street_number, "El número");
    validateNumericField("postal_code", formData.postal_code, "El código postal");
    validateNumericField("phone", formData.phone, "El teléfono");

    if (!formData.vehicle_type) {
      formErrors.value.vehicle_type = "El tipo de vehículo es obligatorio.";
    }
    if (!formData.brands || formData.brands.length === 0) {
      formErrors.value.brands = "Debe seleccionar al menos una marca.";
    }
    return Object.keys(formErrors.value).length === 0;
  };

  return {
    formErrors,
    validateConcessionaireForm,
    validateEmail,
    validateNumericField,
  };
};
