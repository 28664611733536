import axios from '@/plugins/axios';

export const fetchRegionals = async (params) => {
    try {
        const response = await axios.get('/regionals', {
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};

export const createRegional = async (regionalData) => {
    const response = await axios.post('/regionals', regionalData);
    return response.data;
  };
  
  export const updateRegional = async (id, regionalData) => {
    const response = await axios.put(`/regionals/${id}`, regionalData);
    return response.data;
  };

  export const deleteRegional = async (id) => {
    const response = await axios.delete(`/regionals/${id}`);
    return response.data;
  };