<template>
  <div
    v-if="isOpen"
    class="modal fade show d-block"
    tabindex="-1"
    aria-labelledby="viewsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="viewsModalLabel" class="modal-title">
            Vistas asignadas a {{ user?.name || "Usuario" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Cerrar"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading" class="loading">
            <p>Cargando vistas...</p>
          </div>
          <div v-else>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th style="vertical-align: middle">Nombre</th>
                  <th
                    class="text-center"
                    style="vertical-align: middle; position: relative"
                  >
                    <label
                      class="form-check-label d-flex align-items-center justify-content-center"
                    >
                      <input
                        type="checkbox"
                        :checked="allSelected"
                        @change="toggleAll"
                        class="form-check-input me-2"
                        aria-label="Seleccionar o deseleccionar todas las vistas"
                      />
                      <span class="d-inline-flex align-items-center">
                        Activar Todos
                      </span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="view in views" :key="view.id">
                  <td>{{ view.name || "Sin nombre" }}</td>
                  <td class="text-center">
                    <input
                      type="checkbox"
                      :checked="isViewSelected(view.id)"
                      @change="toggleView(view.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="views.length === 0" class="no-results">
              <p class="text-center text-muted">
                No se encontraron vistas asignadas.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="saveViews"
            :disabled="isLoading"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import Swal from "sweetalert2";
import { fetchViewsApi } from "@/services/api/viewService";
import {
  fetchUserViews,
  syncUserViews,
} from "@/services/api/concessionaire-user";

export default {
  name: "ViewsModal",
  props: {
    user: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const views = ref([]);
    const selectedViews = ref([]);
    const isLoading = ref(false);

    const isViewSelected = (viewId) => selectedViews.value.includes(viewId);

    const allSelected = computed(
      () =>
        views.value.length > 0 &&
        views.value.every((view) => selectedViews.value.includes(view.id))
    );

    const loadViews = async () => {
      if (!props.user || !props.user.id) return;
      isLoading.value = true;

      try {
        const allViews = await fetchViewsApi({ privacy: 1 });

        const userViews = await fetchUserViews(props.user.id);

        selectedViews.value = userViews.map((view) => view.id);

        views.value = allViews.data.map((view) => ({
          ...view,
          selected: selectedViews.value.includes(view.id),
        }));
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron cargar las vistas.",
        });
      } finally {
        isLoading.value = false;
      }
    };

    const toggleView = (viewId) => {
      if (selectedViews.value.includes(viewId)) {
        selectedViews.value = selectedViews.value.filter((id) => id !== viewId);
      } else {
        selectedViews.value.push(viewId);
      }

      const view = views.value.find((v) => v.id === viewId);
      if (view) view.selected = !view.selected;
    };

    const toggleAll = () => {
      if (allSelected.value) {
        selectedViews.value = [];
      } else {
        selectedViews.value = views.value.map((view) => view.id);
      }

      views.value.forEach((view) => {
        view.selected = selectedViews.value.includes(view.id);
      });
    };

    const saveViews = async () => {
      if (!props.user || !props.user.id) {
        Swal.fire({
          icon: "warning",
          title: "Advertencia",
          text: "No hay un usuario seleccionado.",
        });
        return;
      }

      try {
        await syncUserViews(props.user.id, selectedViews.value);

        Swal.fire({
          icon: "success",
          title: "Éxito",
          text: "Vistas sincronizadas correctamente.",
        });

        emit("success");
        closeModal();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron sincronizar las vistas.",
        });
      }
    };

    const closeModal = () => {
      emit("close");
    };

    watch(
      () => props.user,
      (newUser) => {
        if (newUser) loadViews();
      },
      { immediate: true }
    );

    return {
      views,
      selectedViews,
      isLoading,
      toggleView,
      toggleAll,
      saveViews,
      closeModal,
      isViewSelected,
      allSelected,
    };
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 700px;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: #007bff;
  color: #fff;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 1rem 1.5rem;
}

.modal-header .btn-close {
  color: #fff;
}

.modal-header .btn-close:hover {
  color: #d9d9d9;
}

.modal-body {
  padding: 1.5rem;
}

.table {
  margin-bottom: 0;
}

.table th {
  background-color: #f8f9fa;
}

.no-results {
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background-color: #f8f9fa;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-check-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.form-check-input {
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px; 
}

th .form-check-label span {
  margin-left: 0.5rem; 
}

</style>
