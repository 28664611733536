<template>
  <div class="d-flex justify-content-end">
    <button
      @click="$emit('fetch-views', 1)"
      class="btn btn-sm btn-outline-secondary mr-2"
      :disabled="currentPage === 1"
    >
      Primera
    </button>
    <button
      @click="$emit('fetch-views', currentPage - 1)"
      class="btn btn-sm btn-outline-secondary mr-2"
      :disabled="currentPage === 1"
    >
      Anterior
    </button>
    <button
      @click="$emit('fetch-views', currentPage + 1)"
      class="btn btn-sm btn-outline-secondary mr-2"
      :disabled="!hasMorePages"
    >
      Siguiente
    </button>
    <button
      @click="$emit('fetch-views', lastPage)"
      class="btn btn-sm btn-outline-secondary"
      :disabled="currentPage === lastPage"
    >
      Última
    </button>
  </div>
</template>

<script>
export default {
  name: "DataPagination",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    hasMorePages: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
