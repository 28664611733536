<template>
  <div>
    <!-- Encabezado con título y botón -->
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h1 class="mb-0" style="font-size: 2rem">Permisos</h1>
        <div>
          <button class="btn btn-primary" @click="openModalImportar">
            Nuevo Usuario
          </button>
        </div>
      </div>

      <!-- Input de búsqueda -->
      <SearchInput v-model="searchTerm" />

      <!-- Tabla de usuarios -->
      <div class="mb-3">
        <span>Página {{ currentPage }} de {{ lastPage }}</span>
      </div>
      <div v-if="loading" class="text-center">Cargando...</div>
      <div v-else>
        <table class="table table-striped table-bordered" style="width: 100%">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>CUIT</th>
              <th>DNI</th>
              <th>Email</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.name || "N/A" }}</td>
              <td>{{ user.lastname || "N/A" }}</td>
              <td>{{ user.cuit || "N/A" }}</td>
              <td>{{ user.dni || "N/A" }}</td>
              <td>{{ user.email || "N/A" }}</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-outline-primary me-1"
                  @click="openViewsModal(user)"
                >
                  <i class="fas fa-eye"></i> Permisos
                </button>
              <!--   <button
                  class="btn btn-sm btn-outline-danger"
                  @click="deleteUser(user.id)"
                >
                  <i class="fas fa-trash"></i> Eliminar
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Paginaación -->
        <DataPagination
          :current-page="currentPage"
          :last-page="lastPage"
          @fetch-views="loadUsers"
        />
      </div>

      <!-- Modal para permisos -->
      <ModalPermisos
        :isOpen="modalOpen"
        :user="selectedUser"
        @close="handleModalClose"
        @success="handleModalSuccess"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { debounce } from "lodash"; 
import { fetchUsers } from "@/services/api/UserService.js";
import SearchInput from "@/components/data-display/SearchInput.vue";
import DataPagination from "@/components/data-display/DataPagination.vue";
import ModalPermisos from "@/components/permisos/ModalPermisos.vue";
import Swal from "sweetalert2";

export default {
  name: "PermisosView",
  components: {
    SearchInput,
    DataPagination,
    ModalPermisos,
  },
  setup() {
    const users = ref([]);
    const searchTerm = ref("");
    const currentPage = ref(1);
    const lastPage = ref(1);
    const loading = ref(false);
    const modalOpen = ref(false);
    const selectedUser = ref(null);

    const loadUsers = async (page = 1) => {
      loading.value = true;
      currentPage.value = page;
      try {
        const response = await fetchUsers({
          page: currentPage.value,
          search: searchTerm.value,
          perPage: 10,
        });
        users.value = response.data;
        currentPage.value = response.meta.current_page;
        lastPage.value = response.meta.last_page;
      } catch (error) {
        console.error("Error al cargar usuarios:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudieron cargar los usuarios.",
        });
      } finally {
        loading.value = false;
      }
    };

    const openViewsModal = (user) => {
      selectedUser.value = user;
      modalOpen.value = true;
    };

    const handleModalClose = () => {
      modalOpen.value = false;
    };

    const handleModalSuccess = () => {
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: "Permisos actualizados correctamente.",
      });
      modalOpen.value = false;
      loadUsers();
    };

  /*   const deleteUser = async (userId) => {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
      });

      if (confirmed.isConfirmed) {
        loadUsers();
      }
    }; */

    const openModalImportar = () => {
      console.log("Abrir modal para agregar un nuevo usuario");
    };

    const debouncedFetchUsers = debounce(() => {
      loadUsers(1);
    }, 500);

    watch(searchTerm, () => {
      debouncedFetchUsers();
    });

    onMounted(() => {
      loadUsers();
    });

    return {
      users,
      searchTerm,
      currentPage,
      lastPage,
      loading,
      loadUsers,
      openViewsModal,
      handleModalClose,
      handleModalSuccess,
      modalOpen,
      selectedUser,
      openModalImportar,
    };
  },
};
</script>

<style scoped>
.table th,
.table td {
  text-align: center;
  vertical-align: middle;
}

.img-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
