import axios from '@/plugins/axios';

const fileManagerService = {
  /**
   * Subir un archivo al servidor.
   * @param {File} file - El archivo a subir.
   * @returns {Promise} - La respuesta de la API.
   */
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post('/file-manager', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * Eliminar un archivo del servidor.
   * @param {string} path - La ruta del archivo a eliminar.
   * @returns {Promise} - La respuesta de la API.
   */
  deleteFile(path) {
    return axios.delete('/file-manager', {
      data: { path },
    });
  },
};

export default fileManagerService;
