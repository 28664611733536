import { ref } from "vue";
import { fetchConcessionairesApi } from "@/services/api/concessionaireService.js";
import { getConcessionaireTypeValue } from "@/constants/concessionaireTypes.js";

export function useConcessionaire() {
  const concessionaires = ref([]);
  const loading = ref(false);
  const error = ref(null);
  const currentPage = ref(1);
  const lastPage = ref(1);
  const hasMorePages = ref(false);

  /**
   * Fetches concessionaires data.
   * @param {Object} options - Options for the API call.
   * @param {string} options.subMenuLabel - The current submenu label.
   * @param {number} options.page - The current page number.
   * @param {number} options.perPage - The number of items per page.
   * @param {string} options.search - The search term to filter results.
   */
  const fetchConcessionaires = async ({
    subMenuLabel = "",
    page = 1,
    perPage = 15,
    search = "",
  } = {}) => {
    loading.value = true;
    concessionaires.value = [];
    error.value = null;
  
    try {
      const subMenu = getConcessionaireTypeValue(subMenuLabel);
      if (!subMenu) {
        throw new Error(`SubMenu no válido: ${subMenuLabel}`);
      }
  
      const response = await fetchConcessionairesApi({
        page,
        perPage,
        search,
        type: subMenu,
      });
  
      if (response.data?.data) {
        // Ajustando a la nueva estructura
        concessionaires.value = response.data.data;
        currentPage.value = response.data.meta?.current_page || 1;
        lastPage.value = response.data.meta?.last_page || 1;
        hasMorePages.value =
          response.data.meta?.current_page < response.data.meta?.last_page;
      } else {
        throw new Error(response.data?.message || "Error desconocido.");
      }
    } catch (err) {
      error.value = err.response?.data?.message || err.message || "Error desconocido.";
      concessionaires.value = [];
    } finally {
      loading.value = false;
    }
  };
  
  

  return {
    concessionaires,
    loading,
    error,
    currentPage,
    lastPage,
    hasMorePages,
    fetchConcessionaires,
  };
}
