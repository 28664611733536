<template>
  <div class="modal fade" id="cardModal" tabindex="-1" aria-labelledby="sliderModalLabel" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-custom-width">
      <div class="modal-content">
        <ModalHeader :view="view" subtitle="Tarjetas" modalId="cardModal" @close="closeModal" />
        
        <div class="modal-body">
          <div class="d-flex justify-content-between mb-3">
            <SearchInput v-model="searchTerm" />
            <button class="btn btn-primary" @click="openNewCardForm">Nuevo</button>
          </div>

          <div v-if="loading" class="text-center">Cargando...</div>

          <div v-else>
            <div class="table-responsive" style="padding-bottom: 20px">
              <table ref="table" class="table table-bordered table-striped table-responsive-stack" :id="CARD_MODAL_ID">
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Contenido</th>
                    <th>URL</th>
                    <th>Imagen</th>
                    <th>Estado</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="card in cards" :key="card.id">
                    <td data-label="Title">{{ card.title }}</td>
                    <td data-label="Content">{{ card.content }}</td>
                    <td data-label="Link">{{ card.link }}</td>
                    <td data-label="Image">
                      <template v-if="card.image.endsWith('.webm')">
                        <video class="img-thumbnail" style="max-width: 100px; max-height: 100px" controls>
                          <source :src="card.image" type="video/webm" />
                          Tu navegador no soporta el formato de video.
                        </video>
                      </template>
                      <template v-else>
                        <img :src="card.image" alt="Image" class="img-thumbnail" style="max-width: 100px; max-height: 100px" />
                      </template>
                    </td>
                    <td data-label="Created At">{{ card.created_at }}</td>
                    <td data-label="Updated At">{{ card.updated_at }}</td>
                    <td data-label="Estado">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'switch-' + card.id"
                          v-model="card.status"
                          :true-value="2"
                          :false-value="1"
                          @change="updateCardStatusSwitch(card)"
                        />
                        <label :for="'switch-' + card.id"></label> 
                      </div>
                    </td>
                    <td data-label="Opciones">
                      <button class="btn btn-sm btn-outline-primary" @click="editCard(card)">Editar</button>
                      <button class="btn btn-sm btn-outline-danger" @click="removeCard(card.id)">Eliminar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataPagination
              :current-page="currentPage"
              :last-page="lastPage"
              :has-more-pages="hasMorePages"
              @fetch-views="loadCards"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <CardFormModal
    v-if="showCardForm"
    :view="view"
    :header="selectedCard"
    @close="closeCardForm"
    @card-created="loadCards"
    @success="handleSuccess"
    @error="handleError"
  />
</template>

<script>
import { ref, onMounted, watch, reactive } from "vue";
import { Modal } from "bootstrap";
import ModalHeader from '@/components/shared/ModalHeader.vue';
import CardFormModal from "./CardFormModal.vue";
import DataPagination from "@/components/data-display/DataPagination.vue";
import SearchInput from "@/components/data-display/SearchInput.vue";
import userConfirmation from "@/utils/userConfirmation.js";
import { fetchCards, deleteCard, updateCardStatus } from "@/services/api/cardService.js";
import { usePagination } from "@/composables/usePagination";
import { useNotification } from "@/utils/notificationUtils";
import { updateStatus } from "@/utils/elements/statusUpdater.js";

const CARD_MODAL_ID = "cardModal";

export default {
  components: {
    ModalHeader,
    CardFormModal,
    DataPagination,
    SearchInput,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const cards = reactive([]);
    const loading = ref(false);
    const showCardForm = ref(false);
    const selectedCard = ref(null);
    const searchTerm = ref("");

    const { currentPage, lastPage, hasMorePages } = usePagination();
    const { handleSuccess, handleError } = useNotification();




    const loadCards = async (page = 1) => {
      loading.value = true;
      try {
        const response = await fetchCards(
          props.view.id,
          page,
          10,
          searchTerm.value
        );
        cards.splice(0, cards.length, ...response.data);
        currentPage.value = response.meta.current_page || 1;
        lastPage.value = response.meta.last_page || 1;
        hasMorePages.value = currentPage.value < lastPage.value;
      } catch (error) {
        console.error("Error fetching cards:", error);
      } finally {
        loading.value = false;
      }
    };

    watch(searchTerm, () => {
      loadCards(1);
    });

    const openNewCardForm = () => {
      selectedCard.value = null;
      showCardForm.value = true;
    };

    const closeCardForm = () => {
      showCardForm.value = false;
    };

    const closeModal = () => {
      emit('close');
    };

    const editCard = (card) => {
      selectedCard.value = card;
      showCardForm.value = true;
    };

    const removeCard = async (cardId) => {
      const confirmed = await userConfirmation("Está seguro de eliminar la Card seleccionada");
      if (!confirmed) return;
      try {
        await deleteCard(cardId);
        loadCards(currentPage.value);
      } catch (error) {
        console.error("Error eliminando card:", error);
      }
    };

    const updateCardStatusSwitch = (card) => {
      updateStatus(card, updateCardStatus, "card");
    };

    onMounted(() => {
      loadCards();
      const modalElement = document.getElementById(CARD_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      cards,
      loading,
      showCardForm,
      selectedCard,
      searchTerm,
      currentPage,
      lastPage,
      hasMorePages,
      loadCards,
      removeCard,
      openNewCardForm,
      closeCardForm,
      closeModal,
      editCard,
      handleSuccess,
      handleError,
      updateCardStatusSwitch,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-custom-width {
  max-width: 85%;
}

.img-thumbnail {
  object-fit: cover;
}

.custom-switch {
  display: inline-block;
  width: 43px;
  height: 28px;
  position: relative;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  left: 1px;
  bottom: 1px;
  transition: transform 0.3s;
}

.custom-switch input:checked + label {
  background-color: #4caf50;
}

.custom-switch input:checked + label:before {
  transform: translateX(20px);
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    display: block;
    border-bottom: 3px solid #ccc;
  }

  .table-responsive-stack th {
    display: none;
  }

  .table-responsive-stack td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-content {
    width: 100%;
  }

  .modal-custom-width {
    max-width: 100%;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

tbody {
  width: 100%;
}
</style>
