import axios from '@/plugins/axios';

export const fetchDirectorApi = async ({ page, perPage, search, order, section  }) => {
    return axios.get("/directors", {
        params: {
            page: page,
            perPage: perPage,
            search: search,
            orderBy: order,
            orderByMethod: "ASC",
            section, 
        },
        withCredentials: false,
    });
};


export const updateDirectorsOrder = async (directors) => {
    try {
        const response = await axios.post('/directors/update-order', {
            directors: directors.map((director) => ({
                id: director.id,
                order: director.order,
            })),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating directors order:', error);
        throw error;
    }
};


export const deleteDirector = async (headerId) => {
    try {
        await axios.delete(`/directors/${headerId}`);
    } catch (error) {
        console.error('Error deleting header:', error);
        throw error;
    }
};

export const createDirector = async (formData) => {
    try {
        filterFormData(formData);

        const response = await axios.post('/directors', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating slider:', error);
        throw error;
    }
};

export const updateDirector = async (id, formData) => {
    try {
        formData.append('_method', 'PUT');

        filterFormData(formData);

        const response = await axios.post(`/directors/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating slider:', error);
        throw error;
    }
};

export const updateDirectorStatus = async (cardId, status, viewId) => {
    try {
        const response = await axios.put(`/directors/${cardId}`, {
            status,
            view_id: viewId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating slider status:', error);
        throw error;
    }
};

const filterFormData = (formData) => {
    for (let key of formData.keys()) {
        const value = formData.get(key);
        if (value === null || value === "null" || value === undefined || value === '') {
            formData.set(key, "");
        }
    }
};
