<template>
  <div
    class="modal fade"
    id="helpModal"
    tabindex="-1"
    aria-labelledby="helpModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-custom-bg">
        <div class="modal-header modal-header-custom">
          <h5 class="modal-title" id="helpModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body text-center">
          <img :src="imageSrc" class="img-fluid modal-image" alt="Help Image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  props: {
    field: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageSrc() {
      return require(`@/assets/images/${this.field}.png`);
    },
    title() {
      return this.field.replace("_", " ").toUpperCase();
    },
  },
  methods: {
    closeModal() {
      const modalElement = document.getElementById("helpModal");
      const modalInstance = Modal.getInstance(modalElement);

      modalElement.addEventListener("hidden.bs.modal", () => {
        if (modalInstance) {
          modalInstance.dispose(); 
        }
      });

      if (modalInstance) {
        modalInstance.hide();
      }

      this.$emit("close"); 
    },
    showModal() {
      const modalElement = document.getElementById("helpModal");
      let modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.dispose();
      }
      modalInstance = new Modal(modalElement, { backdrop: "static" });
      modalInstance.show();
    },
  },
  watch: {
    field() {
      this.showModal();
    },
  },
  mounted() {
    this.showModal();
  },
};
</script>

<style scoped>
.modal-custom-bg {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 0px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal-header-custom {
  background-color: #164d89;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
}

.modal-header-custom .btn-close {
  filter: brightness(0) invert(1);
}

.modal-body {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
</style>
