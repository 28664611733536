<template>
  <div class="row m-0 align-items-center border p-3 rounded">
    <div class="col-12 col-md-8">
      <label :for="id">{{ label }}</label>
      <i
        v-if="helpId"
        class="bi bi-question-circle cursor-pointer"
        @click="$emit('show-help', helpId)"
      ></i>
      <input
        type="file"
        @change="handleFileUpload"
        class="form-control"
        :id="id"
        :required="required"
        :accept="accept"
      />
      <small class="form-text text-muted">{{
        fileName || noFileMessage
      }}</small>
    </div>

    <div
      class="col-12 col-md-4 mt-3 mt-md-0 d-flex justify-content-center align-items-center"
    >
      <div class="preview-container">
        <div v-if="previewSrc" class="mb-2 mt-2 text-center">
          <img
            v-if="isImage"
            :src="previewSrc"
            alt="Preview"
            class="img-thumbnail"
            style="max-width: 100%; max-height: 200px"
          />

          <video
            v-else-if="isVideo"
            controls
            style="max-width: 100%; max-height: 200px"
          >
            <source :src="previewSrc" type="video/webm" />
            Tu navegador no soporta la etiqueta de video.
          </video>

          <a
            v-else-if="isPdf"
            :href="previewSrc"
            target="_blank"
            class="text-white"
            >{{ fileName }}</a
          >
          <span v-else class="text-white">{{ fileName }}</span>
        </div>
        <div v-else class="text-center p-3">SIN ARCHIVO PARA MOSTRAR</div>
      </div>
    </div>
  </div>
  <HelpModal v-if="helpField" :field="helpField" @close="helpField = null" />
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import Swal from "sweetalert2";
import HelpModal from "@/components/shared/HelpModal.vue";

export default {
  components: {
    HelpModal,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    file: {
      type: [File, String],
      default: null,
    },
    preview: {
      type: String,
      default: "",
    },
    noFileMessage: {
      type: String,
      default: "No se ha seleccionado ningún archivo",
    },
    accept: {
      type: String,
      default: "",
    },
    helpId: {
      type: String,
      default: null,
    },
  },
  emits: ["file-change"],
  setup(props, { emit }) {
    const previewSrc = ref(props.preview || "");
    const isImage = ref(false);
    const isPdf = ref(false);
    const isVideo = ref(false);

    watch(
      () => props.preview,
      (newPreview) => {
        if (newPreview === null) {
          previewSrc.value = null;
          isImage.value = false;
          isPdf.value = false;
          isVideo.value = false;
        } else {
          previewSrc.value = newPreview;
          isImage.value = /\.(jpeg|jpg|gif|png)$/i.test(newPreview);
          isPdf.value = /\.pdf$/i.test(newPreview);
          isVideo.value = /\.webm$/i.test(newPreview);

        }
      },
      { immediate: true }
    );
    onMounted(() => {
      console.log(
        "El componente FileUploadPreview se ha renderizado con los siguientes props:"
      );
    });

    const fileName = computed(() => {
      if (props.file && typeof props.file !== "string") {
        return props.file.name;
      } else if (typeof props.file === "string") {
        return props.file.split("/").pop();
      }
      return "";
    });

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const allowedTypes = props.accept.split(",").map((type) => type.trim());
        const isValidType = allowedTypes.some((type) => {
          if (type === "image/*" && file.type.startsWith("image/")) {
            return true;
          }
          return file.type === type;
        });

        if (!isValidType) {
          Swal.fire({
            icon: "error",
            title: "Archivo no permitido",
            text: `Solo se permiten archivos de tipo ${props.accept}.`,
            confirmButtonText: "Aceptar",
          });
          event.target.value = "";
          return;
        }

        emit("file-change", file);
        const reader = new FileReader();
        reader.onload = (e) => {
          previewSrc.value = e.target.result;
          isImage.value = file.type.startsWith("image/");
          isPdf.value = file.type === "application/pdf";
          isVideo.value = file.type === "video/webm";
        };
        reader.readAsDataURL(file);
      } else {
        previewSrc.value = "";
        isImage.value = false;
        isPdf.value = false;
        isVideo.value = false;
      }
    };

    return {
      previewSrc,
      handleFileUpload,
      fileName,
      isImage,
      isPdf,
      isVideo,
    };
  },
};
</script>

<style scoped>
.preview-container {
  border: 1px solid #ccc;
  min-height: 100px;
  margin: 5px;
  background-color: #305785;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.cursor-pointer {
  margin-left: 5px;
  cursor: pointer;
}
</style>
