<template>
  <div class="container p-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="mb-0" style="font-size: 2rem">{{ computedTitle.label }}</h1>
      <div class="d-flex align-items-center mt-6 mt-md-0">
        <button @click="openModalImportar" class="btn btn-primary">
          Importar
        </button>
      </div>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>
    <div class="mb-3">
      <span>Página {{ currentPage }} de {{ lastPage }}</span>
    </div>
    <div v-if="loading" class="text-center">Loading...</div>
    <div v-else>
      <table
        ref="table"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead class="thead-dark">
          <tr>
            <th>Marca</th>
            <th>Modelo</th>
            <th>Versión</th>
            <th>Moneda</th>
            <th>Okm</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="price in prices" :key="price.id">
            <td>{{ price.brand }}</td>
            <td>{{ price.model }}</td>
            <td>{{ price.version }}</td>
            <td>{{ price.currency }}</td>
            <td>{{ price["0km"] }}</td>
            <td class="text-center d-flex justify-content-center">
              <button
               @click="viewMore(price)"
                class="btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
                style="
                  border-radius: 50%;
                  width: 36px;
                  height: 36px;
                  padding: 0;
                  position: relative;
                "
              >
                <i class="fas fa-eye" style="font-size: 1.2rem"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <button
          @click="fetchPrices(1)"
          class="btn btn-sm btn-outline-secondary mr-2"
          :disabled="currentPage === 1"
        >
          Primera
        </button>
        <button
          @click="fetchPrices(currentPage - 1)"
          class="btn btn-sm btn-outline-secondary mr-2"
          :disabled="currentPage === 1"
        >
          Anterior
        </button>
        <button
          @click="fetchPrices(currentPage + 1)"
          class="btn btn-sm btn-outline-secondary mr-2"
          :disabled="!hasMorePages"
        >
          Siguiente
        </button>
        <button
          @click="fetchPrices(lastPage)"
          class="btn btn-sm btn-outline-secondary"
          :disabled="currentPage === lastPage"
        >
          Última
        </button>
      </div>
    </div>
  </div>

  <ModalFormPrecios
    @import-completed="fetchPrices"
    @export-completed="handleExportCompleted"
    ref="modalFormPrecios"
    :subMenu="computedTitle"
    @closeModal="closeModal"
  />

  <ModalDetails
    v-if="modalData"
    :modalData="modalData"
    @close="modalData = null"
  />
</template>

<script>
import ModalFormPrecios from "@/components/precios/ModalFormPrecios.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalDetails from "@/components/precios/ModalDetails.vue";
import { sidebarMenuItemsService } from "@/services/api/sidebarMenuItemsService.js";
import { fetchPricesApi, searchPrices } from "@/services/api/priceService.js";

export default {
  name: "PreciosView",
  components: {
    ModalFormPrecios,
    ModalDetails,
  },

  data() {
    return {
      modalData: null,
      prices: [],
      loading: false,
      error: null,
      lastPage: 1,
      currentPage: 1,
      perPage: 15,
      hasMorePages: false,
      searchTerm: "",
      selectedType: null,
      menuItemsPrecio:
        sidebarMenuItemsService.find((item) => item.link === "/main/precios") ||
        {},
    };
  },

  created() {
    this.updateType();
  },

  computed: {
    computedTitle() {
      const selectedMenuItem = this.menuItemsPrecio.subMenu.find(
        (item) => item.link === `/main/precios/${this.$route.params.subMenu}`
      );
      return selectedMenuItem || { label: "No encontrado" };
    },
  },

  methods: {
    openModalImportar() {
      this.$refs.modalFormPrecios.showModal();
    },
    closeModal() {
      this.$refs.modalFormPrecios.hideModal();
    },

    async handleExportCompleted() {
      await this.fetchPrices();
    },

    viewMore(price) {
      this.modalData = price;
    },

    async fetchPrices(page = 1) {
      this.loading = true;
      try {
        const response = await fetchPricesApi({
          page: page,
          perPage: this.perPage,
          search: this.searchTerm,
          type: this.selectedType ? this.selectedType.value : null,
        });
        if (response.data) {
          this.prices = response.data.data;
          this.currentPage = response.data.meta.current_page;
          this.hasMorePages = response.data.links.next !== null;
          this.lastPage = response.data.meta.last_page;
        } else {
          this.error = response.data.message;
        }
      } catch (error) {
        this.error = error.response
          ? error.response.data.message
          : error.message;
      } finally {
        this.loading = false;
      }
    },

    updateType() {
      this.selectedType = this.menuItemsPrecio.subMenu.find(
        (item) => item.link === `/main/precios/${this.$route.params.subMenu}`
      );
      this.fetchPrices();
    },

    getTypeLabel(idTipo) {
      const type = this.menuItemsPrecio.subMenu.find(
        (item) => item.value == idTipo
      );
      return type ? type.label : "Desconocido";
    },
  },

  watch: {
    async searchTerm() {

      if (!this.selectedType || !this.selectedType.value) {
        this.error = "El tipo de vehículo es obligatorio.";
        return;
      }

      this.loading = true;

      try {
        const response = await searchPrices({
          page: 1,
          perPage: this.perPage,
          search: this.searchTerm,
          type: this.selectedType.value,
        });
        if (response.data) {
          this.prices = response.data.data;
          this.currentPage = response.data.meta.current_page;
          this.hasMorePages = response.data.links.next !== null;
          this.lastPage = response.data.meta.last_page;
        } else {
          this.error = response.data.message;
        }
      } catch (error) {
        this.error = error.response
          ? error.response.data.message
          : error.message;
      } finally {
        this.loading = false;
      }
    },
    "$route.params.subMenu"() {
      this.updateType();
    },
  },
};
</script>

<style scoped>
.btn-icon {
  background: none;
  border: none;
  padding: 0;
}

.btn-icon i {
  color: inherit;
  font-size: 1.2rem;
}
.custom-thead th:last-child {
  text-align: center;
}
</style>
