import axios from "@/plugins/axios";



export async function validateToken() {
  try {
    const response = await axios.post("/check-login");
    if (response.data) {
      return true;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error("Token no válido o expirado");
    } else {
      console.error("Error al validar el token", error);
    }
    return false;
  }
}