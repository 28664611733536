import axios from '@/plugins/axios';


export const fetchCards = async (viewId, page = 1, perPage, search) => {
    try {
        const params = { viewId, page, perPage };
        if (search) {
            params.search = search;
        }

        const response = await axios.get('/cards', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching cards:', error);
        throw error;
    }
};

export const deleteCard = async (headerId) => {
    try {
        await axios.delete(`/cards/${headerId}`);
    } catch (error) {
        console.error('Error deleting header:', error);
        throw error;
    }
};

export const createCard = async (formData) => {
    try {
        filterFormData(formData);

        const response = await axios.post('/cards', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating slider:', error);
        throw error;
    }
};

export const updateCard = async (cardId, formData) => {
    try {
        formData.append('_method', 'PUT');
        
        filterFormData(formData);

        const response = await axios.post(`/cards/${cardId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating slider:', error);
        throw error;
    }
};

export const updateCardStatus = async (cardId, status, viewId) => {
    try {
        const response = await axios.put(`/cards/${cardId}`, {
            status,
            view_id: viewId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating slider status:', error);
        throw error;
    }
};

const filterFormData = (formData) => {
    for (let key of formData.keys()) {
        const value = formData.get(key);
        if (value === null || value === "null" || value === undefined || value === '') {
            formData.set(key, "");
        }
    }
};
