import { ref } from "vue";
import Swal from "sweetalert2";
import { getSucursals, changeSucursalStatus } from "@/services/api/concessionaireService";

export function useConcessionaireDisabling() {
  const sucursals = ref([]);
  const concessionaireId = ref(null);

  const showNotification = (type, title, message) => {
    Swal.fire({
      icon: type,
      title,
      text: message,
      confirmButtonText: "Entendido",
      confirmButtonColor: type === "success" ? "#007BFF" : "#d33",
    });
  };

  const confirmAction = async (message) => {
    return Swal.fire({
      icon: "warning",
      title: "¡ATENCIÓN!",
      html: message,
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#007BFF",
      cancelButtonColor: "#6c757d",
    });
  };

  const getConfirmationMessage = (businessName) =>
    `Este concesionario corresponde a la casa central de ${businessName}. Si lo inhabilita, deberá escoger alguna de sus sucursales como nueva casa central.<br><br>¿Desea continuar?`;

  const getActionMessage = (concessionaire, businessName) =>
    concessionaire.status === 1
      ? `Se inhabilitará ${businessName}, por lo tanto, pasará a estar inactivo.<br><br>¿Desea continuar?`
      : `Se habilitará ${businessName}, por lo tanto, pasará a estar activo.<br><br>¿Desea continuar?`;

  const getBusinessName = (concessionaire) =>
    concessionaire.business_name || "el concesionario seleccionado";

  const handleCentralHouseDisabling = async (concessionaire, openModal) => {
    const businessName = getBusinessName(concessionaire);

    try {
      const response = await getSucursals(concessionaire.id);
      sucursals.value = response.data || [];

      if (sucursals.value.length === 0) {
        showNotification(
          "error",
          "No se puede inhabilitar",
          `No es posible inhabilitar el concesionario ${businessName} como Casa Central porque no posee otras sucursales habilitadas.`
        );
        return;
      }

      const result = await confirmAction(getConfirmationMessage(businessName));
      if (!result.isConfirmed) {
        return;
      }

      concessionaireId.value = concessionaire.id;
      openModal(sucursals.value);
    } catch (error) {
      console.error("Error al obtener las sucursales:", error);
      showNotification(
        "error",
        "Error",
        "Ocurrió un error al intentar obtener las sucursales del concesionario."
      );
    }
  };

  const updateConcessionaireStatus = async (concessionaire, fetchConcessionaires) => {
    const statusData = {
      status: concessionaire.status === 1 ? 0 : 1,
    };

    try {
      await changeSucursalStatus(concessionaire.id, statusData);
      fetchConcessionaires();
    } catch (error) {
      console.error("Error al cambiar el estado del concesionario:", error);
      throw error;
    }
  };

  const handleConcessionaireDisabling = async (concessionaire, fetchConcessionaires) => {
    const businessName = getBusinessName(concessionaire);
    const actionMessage = getActionMessage(concessionaire, businessName);

    const result = await confirmAction(actionMessage);

    if (result.isConfirmed) {
      try {
        await updateConcessionaireStatus(concessionaire, fetchConcessionaires);
      } catch (error) {
        console.error("Error durante la actualización del estado:", error);
      }
    } else {
      console.log("Acción cancelada por el usuario.");
    }
  };

  const disableConcessionaire = async (
    concessionaire,
    fetchConcessionaires,
    openModal
  ) => {
    try {
      if (concessionaire.type === 1) {
        await handleCentralHouseDisabling(concessionaire, openModal);
      } else if (concessionaire.type === 2) {
        await handleConcessionaireDisabling(concessionaire, fetchConcessionaires);
      } else {
        console.error("Tipo de concesionario desconocido.");
      }
    } catch (error) {
      console.error(
        "Error al obtener las sucursales o al inhabilitar el concesionario:",
        error
      );
    }
  };

  return {
    sucursals,
    concessionaireId,
    handleCentralHouseDisabling,
    handleConcessionaireDisabling,
    disableConcessionaire,
  };
}
