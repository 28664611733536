<template>
  <div class="container p-4">
    <div class="d-flex justify-content-between align-items-center mb-3 header">
      <h1 class="mb-0 title">Solicitudes de Acceso</h1>
    </div>

    <div class="mb-3">
      <input
        type="text"
        v-model="searchTerm"
        class="form-control"
        placeholder="Buscar..."
      />
    </div>

    <div v-if="loading" class="text-center">Cargando...</div>
    <div v-else>
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-responsive-md">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>DNI</th>
              <th>Correo Electrónico</th>
              <th>Concesionaria</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="registration in registrations" :key="registration.id">
              <td>{{ registration.name }}</td>
              <td>{{ registration.lastname }}</td>
              <td>{{ registration.dni }}</td>
              <td>{{ registration.email }}</td>
              <td>{{ registration.concessionaire.business_name }}</td>
              <td class="text-center">
                <button
                  @click="viewMore(registration)"
                  class="btn btn-sm btn-outline-primary action-button"
                  :disabled="isProcessing"
                >
                  <i class="fas fa-eye"></i> Ver más
                </button>
                <button
                  @click="confirmApprove(registration.id)"
                  class="btn btn-sm btn-success action-button mx-1"
                  :disabled="isProcessing"
                >
                  <span v-if="isProcessing">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-check-circle"></i> Aceptar
                  </span>
                </button>
                <button
                  @click="confirmReject(registration.id)"
                  class="btn btn-sm btn-danger action-button"
                  :disabled="isProcessing"
                >
                  <span v-if="isProcessing">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-times-circle"></i> Rechazar
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="lastPage > 1"
        class="pagination-container d-flex justify-content-end"
      >
        <button
          @click="fetchRegistros(1)"
          class="btn btn-sm btn-outline-secondary"
          :disabled="currentPage === 1"
        >
          Primera
        </button>
        <button
          @click="fetchRegistros(currentPage - 1)"
          class="btn btn-sm btn-outline-secondary"
          :disabled="currentPage === 1"
        >
          Anterior
        </button>
        <button
          @click="fetchRegistros(currentPage + 1)"
          class="btn btn-sm btn-outline-secondary"
          :disabled="!hasMorePages"
        >
          Siguiente
        </button>
        <button
          @click="fetchRegistros(lastPage)"
          class="btn btn-sm btn-outline-secondary"
          :disabled="currentPage === lastPage"
        >
          Última
        </button>
      </div>
    </div>

    <RegistrationModal
      v-if="isRegistrationModalOpen"
      :isOpen="isRegistrationModalOpen"
      :registration="selectedRegistration"
      @close="isRegistrationModalOpen = false"
      @open-positions-modal="openPositionsModal"
      @approve="confirmApprove"
      @reject="confirmReject"
    />

    <PositionsModal
      v-if="isPositionsModalOpen"
      :isOpen="isPositionsModalOpen"
      :positions="selectedPositions"
      @close="closePositionsModal"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import RegistrationModal from "./modals/RegistrationModal.vue";
import PositionsModal from "./modals/PositionsModal.vue";
import {
  fetchRegistrations,
  approveRegistration,
  rejectRegistration,
} from "@/services/api/registration.js";
import Swal from "sweetalert2";

export default {
  components: { RegistrationModal, PositionsModal },
  setup() {
    const isProcessing = ref(false);

    const registrations = ref([]);
    const isRegistrationModalOpen = ref(false);
    const selectedRegistration = ref(null);
    const isPositionsModalOpen = ref(false);
    const selectedPositions = ref([]);

    const loading = ref(false);
    const searchTerm = ref("");
    const orderBy = ref("name");
    const orderByMethod = ref("asc");
    const perPage = ref(10);
    const status = ref(0);
    const currentPage = ref(1);
    const lastPage = ref(1);
    const hasMorePages = ref(false);

    const fetchRegistros = async (page = 1) => {
      loading.value = true;
      try {
        const data = await fetchRegistrations({
          search: searchTerm.value,
          orderBy: orderBy.value,
          orderByMethod: orderByMethod.value,
          perPage: perPage.value,
          status: status.value,
          page,
        });
        registrations.value = data.data || [];
        currentPage.value = data.meta.current_page;
        lastPage.value = data.meta.last_page;
        hasMorePages.value = data.meta.current_page < data.meta.last_page;
      } catch (error) {
        console.error("Error fetching registrations:", error);
      } finally {
        loading.value = false;
      }
    };

    const handleModalSuccess = () => {
      fetchRegistros(currentPage.value);
    };

    const viewMore = (registration) => {
      selectedRegistration.value = registration;
      isRegistrationModalOpen.value = true;
    };

    const openPositionsModal = (positions) => {
      selectedPositions.value = positions;
      isPositionsModalOpen.value = true;
    };

    const closePositionsModal = () => {
      isPositionsModalOpen.value = false;
    };

    const confirmApprove = async (registrationId, resolve) => {
      if (isProcessing.value) return;
      isProcessing.value = true;
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción aprobará el registro.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, aprobar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await approveRegistration(registrationId);
            Swal.fire("¡Aprobado!", "El registro ha sido aprobado.", "success");
            await fetchRegistros(currentPage.value);
            if (resolve) resolve();
          } catch (error) {
            Swal.fire("Error", "No se pudo aprobar el registro.", "error");
            console.error("Error approving registration:", error);
          } finally {
            isProcessing.value = false;
          }
        } else {
          isProcessing.value = false;
        }
      });
    };

    const confirmReject = async (registrationId, resolve) => {
      if (isProcessing.value) return;
      isProcessing.value = true;
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción rechazará el registro.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Sí, rechazar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rejectRegistration(registrationId);
            Swal.fire(
              "¡Rechazado!",
              "El registro ha sido rechazado.",
              "success"
            );
            await fetchRegistros(currentPage.value);
            if (resolve) resolve();
          } catch (error) {
            Swal.fire("Error", "No se pudo rechazar el registro.", "error");
            console.error("Error rejecting registration:", error);
          } finally {
            isProcessing.value = false;
          }
        } else {
          isProcessing.value = false;
        }
      });
    };

    watch([searchTerm, orderBy, orderByMethod, perPage, status], () => {
      fetchRegistros(1);
    });

    onMounted(() => {
      fetchRegistros();
    });

    return {
      isProcessing,
      confirmApprove,
      confirmReject,
      registrations,
      isRegistrationModalOpen,
      selectedRegistration,
      isPositionsModalOpen,
      selectedPositions,
      loading,
      searchTerm,
      fetchRegistros,
      handleModalSuccess,
      viewMore,
      openPositionsModal,
      closePositionsModal,
      currentPage,
      lastPage,
      hasMorePages,
      orderBy,
      orderByMethod,
      perPage,
      status,
    };
  },
};
</script>

<style scoped>
.header .title {
  font-size: 1.5rem;
}

.table-responsive-md {
  overflow-x: auto;
}

.table-bordered {
  width: 100%;
  border: 1px solid #dee2e6;
}

.thead-dark th {
  background-color: #343a40;
  color: #fff;
}

.table th,
.table td {
  padding: 12px;
  font-size: 0.875rem;
}

.pagination-container {
  gap: 8px;
}

.action-button {
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .header .title {
    font-size: 1.25rem;
  }

  .table th,
  .table td {
    padding: 8px;
    font-size: 0.75rem;
  }

  .pagination-container {
    flex-direction: column;
    gap: 6px;
    align-items: center;
  }

  .btn {
    font-size: 0.75rem;
    padding: 5px 10px;
  }

  .action-button {
    font-size: 0.75rem;
  }
}

.router-content {
  margin: 0 auto;
  max-width: 1320px;
  width: 100%; 
  padding: 1.5rem; 
  box-sizing: border-box; 
}

</style>
