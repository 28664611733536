<template>
  <div>
    <div class="container p-4">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h1 class="mb-0" style="font-size: 2rem">{{ computedTitle.label }}</h1>
        <div class="d-flex align-items-center mt-6 mt-md-0">
          <button @click="openModalImportar" class="btn btn-primary">
            Nuevo
          </button>
        </div>
      </div>

      <SearchInput v-model="searchTerm" />
      <div class="mb-3">
        <span>Página {{ currentPage }} de {{ lastPage }}</span>
      </div>
      <div v-if="loading" class="text-center">Loading...</div>
      <div v-else>
        <table
          ref="table"
          class="table table-striped table-bordered"
          style="width: 100%"
        >
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Cargo</th>
              <th v-if="computedTitle.value === 1">Imagen</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <draggable
            v-model="directors"
            tag="tbody"
            item-key="id"
            @end="updateOrder"
          >
            <template #item="{ element }">
              <tr :key="element.id">
                <td>{{ element.name }}</td>
                <td>{{ element.lastname }}</td>
                <td>{{ element.position }}</td>
                <td v-if="computedTitle.value === 1" class="text-center">
                  <div v-if="element.image">
                    <img
                      :src="element.image"
                      alt="Imagen de {{ element.name }}"
                      class="img-thumbnail"
                      style="width: 50px; height: 50px; object-fit: cover"
                    />
                  </div>
                  <div v-else>SIN IMAGEN</div>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-sm btn-outline-primary"
                    @click="editDirector(element)"
                  >
                    Editar
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger"
                    @click="removeDirector(element.id)"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            </template>
          </draggable>
        </table>

        <DataPagination
          :current-page="currentPage"
          :last-page="lastPage"
          :has-more-pages="hasMorePages"
          @fetch-views="fetchDirectors"
        />
      </div>
    </div>

    <ModalFormDirector
      @import-completed="fetchDirectors(1, computedTitle.value)"
      @export-completed="handleExportCompleted"
      ref="modalFormDirectors"
      :subMenu="computedTitle"
      :director="selectedDirector"
      @closeModal="closeModal"
      @close-modal="closeModal"
    />

    <ModalDetails
      v-if="modalData"
      :modalData="modalData"
      @close="modalData = null"
    />
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import ModalFormDirector from "@/components/director/ModalFormDirector.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ModalDetails from "@/components/precios/ModalDetails.vue";
import { sidebarMenuItemsService } from "@/services/api/sidebarMenuItemsService.js";
import {
  fetchDirectorApi,
  updateDirectorsOrder,
  deleteDirector,
} from "@/services/api/directorService.js";
import draggable from "vuedraggable";
import userConfirmation from "@/utils/userConfirmation.js";
import DataPagination from "@/components/data-display/DataPagination.vue";
import { usePagination } from "@/composables/usePagination";
import SearchInput from "@/components/data-display/SearchInput.vue";

export default {
  name: "ComisionDirectivaView",
  components: {
    ModalFormDirector,
    ModalDetails,
    draggable,
    DataPagination,
    SearchInput,
  },
  setup() {
    const route = useRoute();
    const modalData = ref(null);
    const directors = ref([]);
    const loading = ref(false);
    const error = ref(null);
    const { currentPage, lastPage, hasMorePages } = usePagination();
    const perPage = ref(10);

    const searchTerm = ref("");
    const selectedType = ref(null);
    const selectedDirector = ref(null);
    const modalFormDirectors = ref(null);

    const menuItemsPrecio = ref(
      sidebarMenuItemsService.find((item) => item.link === "/main/director") ||
        {}
    );

    const computedTitle = computed(() => {
      const selectedMenuItem = menuItemsPrecio.value.subMenu?.find(
        (item) => item.link === `/main/director/${route.params.subMenu}`
      );
      return selectedMenuItem || { label: "No encontrado" };
    });

    const fetchDirectors = async (page = 1, titleValue = null) => {
      loading.value = true;
      try {
        const response = await fetchDirectorApi({
          page,
          perPage: perPage.value,
          search: searchTerm.value,
          order: "order",
          section: titleValue,
        });

        if (response.data) {
          directors.value = response.data.data;
          currentPage.value = response.data.meta.current_page || 1;
          lastPage.value = response.data.meta.last_page || 1;
          hasMorePages.value = currentPage.value < lastPage.value;
        } else {
          error.value = response.data.message;
        }
      } catch (err) {
        error.value = err.response ? err.response.data.message : err.message;
      } finally {
        loading.value = false;
      }
    };

    const updateOrder = async () => {
      directors.value.forEach((director, index) => {
        director.order = index + 1;
      });

      try {
        await updateDirectorsOrder(directors.value);
      } catch (err) {
        console.error("Error al actualizar el orden:", err);
      }
    };

    const editDirector = (director) => {
      selectedDirector.value = null;
      nextTick(() => {
        selectedDirector.value = director;
        modalFormDirectors.value.showModal();
      });
    };

    const openModalImportar = () => {
      selectedDirector.value = null;
      modalFormDirectors.value.showModal();
    };

    const closeModal = () => {
      modalFormDirectors.value.hideModal();
    };

    const handleExportCompleted = async () => {
      await fetchDirectors();
    };

    const removeDirector = async (cardId) => {
      const confirmed = await userConfirmation(
        "Está seguro de eliminar la Card seleccionada"
      );
      if (!confirmed) return;
      try {
        await deleteDirector(cardId);
        fetchDirectors(currentPage.value, computedTitle.value.value);
      } catch (error) {
        console.error("Error eliminando card:", error);
      }
    };

    watch(
      computedTitle,
      (newTitle) => {
        fetchDirectors(1, newTitle.value);
      },
      { immediate: true }
    );

    watch(searchTerm, () => {
      fetchDirectors(currentPage.value, computedTitle.value.value);
    });

    return {
      modalData,
      directors,
      loading,
      error,
      lastPage,
      currentPage,
      perPage,
      hasMorePages,
      searchTerm,
      selectedType,
      computedTitle,
      fetchDirectors,
      updateOrder,
      removeDirector,
      openModalImportar,
      closeModal,
      handleExportCompleted,
      modalFormDirectors,
      editDirector,
      selectedDirector,
    };
  },
};
</script>

<style scoped>
.btn-icon {
  background: none;
  border: none;
  padding: 0;
}

.btn-icon i {
  color: inherit;
  font-size: 1.2rem;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
