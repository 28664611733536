import { createRouter, createWebHistory } from 'vue-router';
import { validateToken } from '@/services/api/authService'; 
import MainLayout from '../layouts/MainLayout.vue';
import HomeView from '../views/HomeView.vue';
import PreciosView from '../views/precios/PreciosView.vue';
import DirectorView from '../views/director/DirectorView.vue';
import LoginView from '../views/LoginView.vue';
import IndicesView from '../views/indices/IndicesView.vue';
import ContenidoView from '../views/contenidos/ContenidoView.vue';
import ViewTable from '../views/vistas/ViewActions.vue';
import ConcessionaireFormView from '../views/administrador/concesionarios/ConcessionaireFormView.vue';
import RegionalesView from '../views/administrador/ajustes/RegionalsView.vue';
import ProvinciasView from '../views/administrador/ajustes/ProvincesView.vue';
import AreasView from '../views/administrador/ajustes/AreasView.vue';
import CargosView from '../views/administrador/ajustes/PositionView.vue';
import ConcessionaireView from '../views/administrador/concesionarios/ConcessionaireView.vue';
import UserFormView from '../views/administrador/usuarios/UserFormView.vue';
import RegistrationView from '../views/administrador/solicitudes-acceso/RegistrationView.vue';
import PermisosView from '../views/administrador/permisos/PermisosView.vue';
// import ContactView from '../views/administrador/contact/ContactView.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/main',
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: { requiresAuth: true },
      },
      {
        path: 'novedades',
        name: 'Novedades',
        component: () => import(/* webpackChunkName: "novedades" */ '../views/novedades/NovedadesView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'precios/:subMenu',
        name: 'PreciosSubMenu',
        component: PreciosView,
        meta: { requiresAuth: true },
      },
      {
        path: 'director/:subMenu',
        name: 'DirectorSubMenu',
        component: DirectorView,
        meta: { requiresAuth: true },
      },
      {
        path: 'contenidos/:subMenu',
        name: 'ContenidoView',
        component: ContenidoView,
        meta: { requiresAuth: true },
      },
      {
        path: 'indices',
        name: 'Indices',
        component: IndicesView,
        props: { menu: 'indices' },
        meta: { requiresAuth: true },
      },
      {
        path: 'vista',
        name: 'Vista',
        component: ViewTable,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/:subMenu',
        name: 'AdministradorSubMenu',
        component: () => import(/* webpackChunkName: "administrador" */ '../views/administrador/AdministradorView.vue'),
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: '/concessionaires-alta',
        name: 'ConcessionaireForm',
        component: ConcessionaireFormView,
        meta: { requiresAuth: true },
      },
      {
        path: '/usuario-alta',
        name: 'UserForm',
        component: UserFormView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/ajustes/regionales',
        name: 'Regionales',
        component: RegionalesView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/ajustes/provincias',
        name: 'Provincias',
        component: ProvinciasView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/ajustes/areas',
        name: 'Areas',
        component: AreasView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/ajustes/cargos',
        name: 'Cargos',
        component: CargosView,
        meta: { requiresAuth: true },
      },
      {
        path: "/administrador/concesionarios/editar",
        name: "ConcessionaireFormStepTwo",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/administrador/concesionarios/ConcessionaireFormStepTwo.vue"),
        props: (route) => ({
          isEditing: route.query.isEditing === "true",
          isAddBranch: route.query.isAddBranch === "true",
        }),
      },
      {
        path: "/administrador/usuarios/editar",
        name: "UserFormStepTwo",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/administrador/usuarios/UserFormStepTwo.vue"),
        props: (route) => ({
          isEditing: route.query.isEditing === "true",
        }),
      },
      {
        path: 'administrador/concessionaires/:subMenu',
        name: 'ConcessionaireSubMenu',
        component: ConcessionaireView,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'administrador/solicitudes-acceso',
        name: 'Registration',
        component: RegistrationView,
        meta: { requiresAuth: true },
      },
      {
        path: 'administrador/permisos-usuarios',
        name: 'Permisos',
        component: PermisosView,
        meta: { requiresAuth: true },
      },
      /*       {
              path: 'administrador/contact',
              name: 'Contact',
              component: ContactView,
            }, */
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("user_access_token");

  // Si la ruta requiere autenticación
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      console.warn("No autenticado: Redirigiendo a login.");
      localStorage.removeItem("user_access_token");
      return next({ name: "login" });
    }

    try {
      const isValid = await validateToken();

      if (!isValid) {
        console.warn("Token inválido o expirado: Redirigiendo a login.");
        localStorage.removeItem("user_access_token");
        return next({ name: "login" });
      }
    } catch (error) {
      console.error("Error al validar el token:", error);
      localStorage.removeItem("user_access_token");
      return next({ name: "login" });
    }
  }

  // Si intenta acceder a la página de login estando autenticado
  if (to.name === "login" && isAuthenticated) {
    console.warn("Usuario autenticado: Redirigiendo a home.");
    return next({ name: "home" });
  }

  next();
});



export default router;
