<template>
  <div>
    <div class="container p-4">
      <div
        class="header d-flex justify-content-between align-items-center mb-3"
      >
        <h1 class="header-title">
          Concesionarios
          <span class="sub-title ml-2 text-muted">
            ({{ currentConcessionaireType }})
          </span>
        </h1>
        <button
          v-if="currentConcessionaireTypeValue === 1"
          @click="modalNew"
          class="btn btn-primary add-button"
        >
          AGREGAR NUEVO <i class="fas fa-plus ml-2"></i>
        </button>
      </div>

      <div class="search-bar mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>

      <div class="pagination-info mb-2">
        <span>Página {{ currentPage }} de {{ lastPage }}</span>
      </div>

      <div v-if="loading" class="text-center">Cargando...</div>
      <div v-else>
        <div v-if="concessionaires.length === 0" class="text-center">
          No hay datos disponibles.
        </div>
        <div v-else class="table-responsive">
          <table ref="table" class="table table-striped table-formal">
            <thead>
              <tr>
                <th>CUIT</th>
                <th>Número de Socio</th>
                <th>Razón Social</th>
                <th>Provincia</th>
                <th>Localidad</th>
                <th>Regional</th>
                <th>Calle</th>
                <th>Tipo</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="concessionaire in concessionaires"
                :key="concessionaire.id"
              >
                <td>{{ concessionaire.cuit || "N/A" }}</td>
                <td>{{ concessionaire.member_number || "N/A" }}</td>
                <td>{{ concessionaire.business_name || "N/A" }}</td>
                <td>{{ concessionaire.province_name || "N/A" }}</td>
                <td>{{ concessionaire.locality_name || "N/A" }}</td>
                <td>{{ concessionaire.regional_name || "N/A" }}</td>
                <td>{{ concessionaire.street_name || "N/A" }}</td>
                <td>{{ getConcessionaireTypeLabel(concessionaire.type) }}</td>
                <td
                  :class="
                    concessionaire.status === 1
                      ? 'status-enabled'
                      : 'status-disabled'
                  "
                >
                  {{
                    concessionaire.status === 1 ? "Habilitado" : "Inhabilitado"
                  }}
                </td>

                <td class="text-center position-relative">
                  <div class="dropdown">
                    <button
                      class="btn btn-link p-0"
                      type="button"
                      @click.stop="toggleDropdown(concessionaire.id)"
                      :aria-expanded="dropdownVisible === concessionaire.id"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <ul
                      v-if="dropdownVisible === concessionaire.id"
                      class="dropdown-menu show"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="editConcessionaire(concessionaire)"
                        >
                          <i class="fas fa-edit"></i> Editar
                        </a>
                      </li>
                      <li v-if="concessionaire.type !== 2">
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="addBranch(concessionaire)"
                        >
                          <i class="fas fa-plus"></i> Agregar Sucursal
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-danger"
                          href="#"
                          @click.prevent="
                            disableConcessionaireHandler(concessionaire)
                          "
                        >
                          <i
                            :class="
                              concessionaire.status === 1
                                ? 'fas fa-ban'
                                : 'fas fa-check-circle'
                            "
                          ></i>
                          {{
                            concessionaire.status === 1
                              ? "Inhabilitar"
                              : "Habilitar"
                          }}
                        </a>
                      </li>
                      <li v-if="concessionaire.type !== 2">
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="viewBranch(concessionaire)"
                        >
                          <i class="fas fa-folder-open"></i> Ver sucursales
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination d-flex justify-content-end mt-3">
          <button
            @click="loadConcessionaires(1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Primera
          </button>
          <button
            @click="loadConcessionaires(currentPage - 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="currentPage === 1"
          >
            Anterior
          </button>
          <button
            @click="loadConcessionaires(currentPage + 1)"
            class="btn btn-sm btn-outline-secondary mr-2"
            :disabled="!hasMorePages"
          >
            Siguiente
          </button>
          <button
            @click="loadConcessionaires(lastPage)"
            class="btn btn-sm btn-outline-secondary"
            :disabled="currentPage === lastPage"
          >
            Última
          </button>
        </div>
      </div>
    </div>
    <SucursalSelector
      :isOpen="modalOpen"
      :sucursals="sucursals"
      :showSelection="showSelection"
      @close="modalOpen = false"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  getConcessionaireTypeLabel,
  getConcessionaireTypeValue,
} from "@/constants/concessionaireTypes.js";
import { changeCentralHouseStatus } from "@/services/api/concessionaireService";
import Swal from "sweetalert2";
import SucursalSelector from "./SucursalSelector.vue";
import { useConcessionaire } from "@/composables/concessionaire/useConcessionaire";
import { useConcessionaireDisabling } from "@/composables/concessionaire/useConcessionaireDisabling";
import { getSucursals } from "@/services/api/concessionaireService.js";

export default {
  name: "ConcesionarioView",
  components: {
    SucursalSelector,
  },
  setup() {
    const selectedSucursal = ref(null);
    const modalOpen = ref(false);
    const showSelection = ref(true);

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const perPage = ref(15);
    const searchTerm = ref("");
    const dropdownVisible = ref(null);

    const {
      concessionaires,
      loading,
      error,
      currentPage,
      lastPage,
      hasMorePages,
      fetchConcessionaires,
    } = useConcessionaire();

    const { sucursals, concessionaireId, disableConcessionaire } =
      useConcessionaireDisabling();

    const disableConcessionaireHandler = async (concessionaire) => {
      showSelection.value = true;
      await disableConcessionaire(
        concessionaire,
        loadConcessionaires,
        openModal
      );
    };

    const viewBranch = async (concessionaire) => {
      try {

        const response = await getSucursals(concessionaire.id);
        sucursals.value = response.data || [];

        if (sucursals.value.length === 0) {
          Swal.fire({
            icon: "info",
            title: "Sin Sucursales",
            text: `El concesionario ${concessionaire.business_name} no tiene sucursales registradas.`,
          });
          return;
        }

        modalOpen.value = true;
        showSelection.value = false;
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al intentar obtener las sucursales del concesionario.",
        });
      }
    };

    const currentConcessionaireType = computed(() => {
      const subMenu = route.params.subMenu;
      const typeValue = getConcessionaireTypeValue(subMenu);
      return getConcessionaireTypeLabel(typeValue);
    });

    const currentConcessionaireTypeValue = computed(() => {
      const subMenu = route.params.subMenu;
      return getConcessionaireTypeValue(subMenu);
    });

    const loadConcessionaires = async (page = 1) => {
      await fetchConcessionaires({
        subMenuLabel: route.params.subMenu,
        page,
        perPage: perPage.value,
        search: searchTerm.value,
      });
    };

    const toggleDropdown = (concessionaireId) => {
      dropdownVisible.value =
        dropdownVisible.value === concessionaireId ? null : concessionaireId;
    };

    const closeDropdown = () => {
      dropdownVisible.value = null;
    };

    const modalNew = () => {
      router.push({ name: "ConcessionaireForm" });
    };

    const editConcessionaire = (concessionaire) => {
      store.dispatch("updateHabitualistaData", concessionaire);
      router.push({
        name: "ConcessionaireFormStepTwo",
        query: {
          isEditing: "true",
        },
      });
      closeDropdown();
    };

    const addBranch = async (concessionaire) => {
      store.dispatch("updateHabitualistaData", concessionaire);
      router.push({
        name: "ConcessionaireFormStepTwo",
        query: {
          isEditing: "true",
          isAddBranch: "true",
        },
      });
      closeDropdown();
    };

    const openModal = () => {
      modalOpen.value = true;
    };

    const handleModalSuccess = ({ id, status }) => {
      if (!id) {
        return;
      }
      selectedSucursal.value = { id, status };
      modalOpen.value = false;
      processSelectedSucursal();
    };

    const processSelectedSucursal = async () => {
      if (!selectedSucursal.value) return;

      const { id, status } = selectedSucursal.value;

      try {
        const response = await changeCentralHouseStatus(
          concessionaireId.value,
          {
            new_central_house_id: id,
            status,
          }
        );

        showSuccessNotification(
          response.message || "Cambio realizado con éxito"
        );
        loadConcessionaires();
      } catch (error) {
        console.error("Error al procesar la sucursal seleccionada:", error);

        showNotification(
          "error",
          "Error",
          "No se pudo cambiar el estado de la casa central. Por favor, intenta de nuevo."
        );
      }
    };

    const showSuccessNotification = (message) => {
      showNotification("success", "¡Éxito!", message);
    };

    const showErrorNotification = (message) => {
      showNotification("error", "¡Error!", message);
    };

    const showNotification = (type, title, message) => {
      Swal.fire({
        icon: type,
        title,
        text: message,
        confirmButtonText: "Entendido",
        confirmButtonColor: type === "success" ? "#007BFF" : "#d33",
      });
    };

    onMounted(() => {
      loadConcessionaires();
      document.addEventListener("click", closeDropdown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", closeDropdown);
    });

    watch(
      () => searchTerm.value,
      () => {
        loadConcessionaires();
      }
    );

    watch(
      () => route.params.subMenu,
      () => {
        loadConcessionaires();
      }
    );

    return {
      showSelection,
      viewBranch,
      disableConcessionaireHandler,
      loadConcessionaires,
      showNotification,
      showErrorNotification,
      showSuccessNotification,
      selectedSucursal,
      handleModalSuccess,
      modalOpen,
      sucursals,
      currentConcessionaireTypeValue,
      currentConcessionaireType,
      concessionaires,
      loading,
      error,
      lastPage,
      currentPage,
      perPage,
      hasMorePages,
      searchTerm,
      fetchConcessionaires,
      modalNew,
      editConcessionaire,
      addBranch,
      disableConcessionaire,
      dropdownVisible,
      toggleDropdown,
      getConcessionaireTypeLabel,
    };
  },
};
</script>

<style scoped>
.status-enabled {
  color: green;
  font-weight: bold;
}

.status-disabled {
  color: red;
  font-weight: bold;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 1.5rem;
}

.add-button {
  font-size: 0.875rem;
  padding: 8px 10px;
  transition: transform 0.1s ease, background-color 0.3s;
}

.add-button:active {
  transform: scale(0.95);
  background-color: #003f7f;
}

.table-responsive {
  position: relative;
  overflow: visible;
}

.table-formal {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  color: #333;
}

.dropdown .dropdown-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  min-width: 150px;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.dropdown .dropdown-menu.show {
  display: block;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 1.25rem;
  }
  .table-formal th,
  .table-formal td {
    padding: 6px;
    font-size: 0.75rem;
  }
}
</style>
