<template>
  <div
    class="modal fade"
    id="formModal"
    tabindex="-1"
    aria-labelledby="cardFormModalLabel"
    :aria-hidden="!isModalVisible"
  >
    <div class="modal-dialog custom-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cardFormModalLabel">
            {{ isEditing ? "Editar Articulo" : "Nuevo Articulo" }}
          </h5>
          <button type="button" class="btn-close" @click="closeForm"></button>
        </div>
        <div class="modal-body fixed-modal-body">
          <form @submit.prevent="submitForm">
            <div class="modal-step" v-if="currentStep === 1">
              <div class="row g-3">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="title" class="form-label fw-bold">Título</label>
                    <input
                      type="text"
                      v-model="form.title"
                      class="form-control shadow-sm"
                      id="title"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="date" class="form-label fw-bold">Fecha</label>
                    <input
                      type="date"
                      v-model="form.date"
                      class="form-control shadow-sm"
                      id="date"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="type" class="form-label fw-bold"
                      >Categoría</label
                    >
                    <select
                      v-model="form.type"
                      class="form-select shadow-sm"
                      id="type"
                    >
                      <option
                        v-for="option in categories"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row g-3">
                <div class="col-md-6">
                  <div class="mb-3 border p-3 rounded shadow-sm">
                    <label for="type" class="form-label fw-bold"
                      >Tipo de Enlace</label
                    >
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="select-addon">
                        <i class="bi bi-link-45deg"></i>
                      </span>
                      <select
                        v-model="form.linkType"
                        class="form-select shadow-sm"
                        id="type"
                        aria-describedby="select-addon"
                      >
                        <option value="link">Enlace</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </div>

                    <div class="mb-3" v-if="form.linkType === 'link'">
                      <label for="link" class="form-label">Enlace</label>
                      <input
                        type="text"
                        v-model="form.link"
                        class="form-control shadow-sm"
                        id="link"
                        required
                      />
                    </div>
                  </div>

                  <FileUploadPreview
                    v-if="form.linkType === 'pdf'"
                    label="Subir PDF"
                    id="file"
                    accept="application/pdf"
                    :file="form.file"
                    :preview="form.file ? form.file.name : ''"
                    @file-change="updateFile('file', $event)"
                    no-file-message="No se ha seleccionado ningún archivo"
                    class="mt-3"
                  />
                </div>

                <div class="col-md-6">
                  <FileUploadPreview
                    label="Imagen"
                    id="image"
                    accept="image/*"
                    :file="form.image"
                    :preview="form.imagePreview"
                    @file-change="updateFile('image', $event)"
                    no-file-message="No se ha seleccionado ninguna imagen"
                  />
                </div>
              </div>

              <div class="d-flex justify-content-end mt-4">
                <button
                  class="btn btn-primary"
                  @click="nextStep"
                  :disabled="!isStep1Valid"
                >
                  Siguiente
                </button>
              </div>
            </div>

            <div class="modal-step" v-if="currentStep === 2">
              <div class="mb-3">
                <label for="content" class="form-label">Contenido</label>
                <quill-editor
                  ref="quillEditor"
                  v-model:content="form.content"
                  :theme="'snow'"
                  class="form-control quill-editor-height"
                  @ready="onEditorReady"
                  @text-change="onTextChange"
                  toolbar="full"
                ></quill-editor>
              </div>

              <button class="btn btn-secondary" @click="prevStep">Atrás</button>
              <button
                type="submit"
                class="btn btn-success"
                :disabled="!isStep2Valid"
              >
                Guardar
              </button>
              {{ isStep2Valid }}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { Modal } from "bootstrap";
import { QuillEditor } from "@vueup/vue-quill";
import Quill from "quill";
import FileUploadPreview from "@/components/shared/FileUploadPreview.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { buildFormData } from "@/utils/formDataBuilder.js";

export default {
  components: {
    FileUploadPreview,
    QuillEditor,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
    header: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isModalVisible = ref(false);
    const showArticleForm = ref(false);
    const currentStep = ref(1);
    const isEditing = ref(false);

    const quillEditor = ref(null);
    const editorReady = ref(false);
    const contentHasText = ref(false);

    const categories = ref([
      { value: 1, label: "AUTOS" },
      { value: 2, label: "MOTOS" },
      { value: 3, label: "CAMIONES" },
      { value: 4, label: "MAQUINARIA AGRICOLA" },
      { value: 5, label: "SOCIOS" },
      { value: 6, label: "ASESORIA LEGAL" },
      { value: 7, label: "ASESORIA IMPOSITIVA" },
      { value: 8, label: "ASESORIA REGISTRAL" },
      { value: 9, label: "CAPACITACIONES" },
    ]);

    const form = ref({
      title: "",
      date: "",
      linkType: "link",
      link: "",
      file: null,
      image: null,
      type: "",
      priority: 0,
      content: "",
      view_id: props.view.id,
    });

    const isStep1Valid = computed(() => {
      return (
        form.value.title &&
        form.value.date &&
        form.value.type &&
        (form.value.linkType === "link" ? form.value.link : form.value.file) &&
        form.value.image
      );
    });

    const isStep2Valid = computed(() => {
      return contentHasText.value;
    });

    watch(
      () => props.header,
      (newHeader) => {
        if (newHeader) {
          isEditing.value = true;
          form.value = {
            title: newHeader.title,
            content: newHeader.content,
            link: newHeader.link,
            file: newHeader.file || null,
            image: newHeader.image || null,
            type: newHeader.type || "",
            priority: newHeader.priority || 0,
            date: newHeader.date,
            imagePreview: newHeader.image,
            linkType: newHeader.link ? "link" : newHeader.file ? "pdf" : "link",
            view_id: newHeader.view.id,
          };
        } else {
          isEditing.value = false;
          form.value.imagePreview = "";
          form.value.linkType = "link";
          form.value.priority = 0;
        }
      },
      { immediate: true }
    );

    const nextStep = () => {
      currentStep.value = 2;
    };

    const prevStep = () => {
      currentStep.value = 1;
    };

    const updateFile = (type, file) => {
      form.value[type] = file;
    };

    const closeForm = () => {
      isModalVisible.value = false;
      emit("close");
      const modalElement = document.getElementById("formModal");
      const modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    };

    const submitForm = () => {
      if (quillEditor.value) {
        const quillInstance = quillEditor.value.getQuill();
        const contentDelta = quillInstance.getContents();
        const quillTemp = new Quill(document.createElement("div"));
        quillTemp.setContents(contentDelta);
        form.value.content = quillTemp.root.innerHTML;
      }

      if (form.value.linkType === "link") {
        form.value.file = null;
      } else if (form.value.linkType === "pdf") {
        form.value.link = null;
      }

      const formData = buildFormData(form.value, form.value.linkType);

      emit("article-submitted", formData);
      closeForm();
    };

    onMounted(() => {
      const modalElement = document.getElementById("formModal");
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
      isModalVisible.value = true;
    });

    const onEditorReady = () => {
      editorReady.value = true;
      if (quillEditor.value && form.value.content) {
        const quillInstance = quillEditor.value.getQuill();

        quillInstance.clipboard.dangerouslyPasteHTML(form.value.content);
      }
    };

    const onTextChange = () => {
      if (quillEditor.value) {
        const quillInstance = quillEditor.value.getQuill();
        contentHasText.value = quillInstance.getText().trim().length > 0;
      }
    };

    return {
      showArticleForm,
      currentStep,
      form,
      categories,
      closeForm,
      nextStep,
      prevStep,
      updateFile,
      submitForm,
      quillEditor,
      onEditorReady,
      onTextChange,
      editorReady,
      isStep1Valid,
      isStep2Valid,
    };
  },
};
</script>

<style scoped>
.custom-modal-dialog {
  max-width: 80%;
}

.modal-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

::v-deep .quill-editor-height {
  height: 600px !important;
}

@media (max-width: 768px) {
  .custom-modal-dialog {
    max-width: 100%;
  }

  .fixed-modal-body {
    min-height: 400px;
    max-height: 400px;
  }

  ::v-deep .quill-editor-height {
    height: 250px !important;
  }
}
</style>
