import axios from '@/plugins/axios';



export const fetchArticles = async (viewId, page = 1, perPage, search) => {
    try {
        const params = { viewId, page, perPage };
        if (search) {
            params.search = search;
        }

        const response = await axios.get('/articles', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching articles:', error);
        throw error;
    }
};

export const deleteArticle = async (headerId) => {
    try {
        await axios.delete(`/articles/${headerId}`);
    } catch (error) {
        console.error('Error deleting header:', error);
        throw error;
    }
};

export const createArticle = async (formData) => {
    try {
        
       filterFormData(formData);

        const response = await axios.post('/articles', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating article:', error);
        throw error;
    }
};

export const updateArticle = async (id, formData) => {
    try {
        
        formData.append('_method', 'PUT');

       filterFormData(formData);

        const response = await axios.post(`/articles/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating article:', error);
        throw error;
    }
};

export const updateArticleStatus = async (id, status, viewId) => {
    try {
        const response = await axios.put(`/articles/${id}`, {
            status,
            view_id: viewId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating article status:', error);
        throw error;
    }
};

const filterFormData = (formData) => {
    for (let key of formData.keys()) {
        const value = formData.get(key);
        if (value === null || value === "null" || value === undefined || value === '') {
            formData.set(key, "");
        }
    }
};
