<template>
  <div class="modal fade" id="sliderModal" tabindex="-1" aria-labelledby="sliderModalLabel" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-custom-width">
      <div class="modal-content">
        <ModalHeader :view="view" subtitle="Slider" modalId="sliderModal" @close="closeModal" />
        <div class="modal-body">
          <div class="d-flex justify-content-between mb-3">
            <SearchInput v-model="searchTerm" />
            <button class="btn btn-primary" @click="openNewSliderForm">Nuevo</button>
          </div>
          <div v-if="loading" class="text-center">Cargando...</div>
          <div v-else>
            <div class="table-responsive" style="padding-bottom: 20px;">
              <table
                ref="table"
                class="table table-bordered table-striped table-responsive-stack"
                :id="SLIDER_MODAL_ID"
              >
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Titutlo</th>
                    <th>Contenido</th>
                    <th>Link</th>
                    <th>Imagen</th>
                    <th>Fecha Creacion</th>
                    <th>Fecha Actualizacion</th>
                    <th>Estado</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="slider in sliders" :key="slider.id">
                    <td data-label="Name">{{ slider.name || "Sin datos disponibles"}}</td>
                    <td data-label="Title">{{ slider.title || "Sin datos disponibles"}}</td>
                    <td data-label="Content">{{ slider.content || "Sin datos disponibles"}}</td>
                    <td data-label="Link">{{ slider.link || "Sin datos disponibles"}}</td>
                    <td data-label="Image">
                      <template v-if="slider.image.endsWith('.webm')">
                        <video class="img-thumbnail" style="max-width: 100px; max-height: 100px;" controls>
                          <source :src="slider.image" type="video/webm">
                          Tu navegador no soporta el formato de video.
                        </video>
                      </template>
                      <template v-else>
                        <img :src="slider.image" alt="Image" class="img-thumbnail" style="max-width: 100px; max-height: 100px;">
                      </template>
                    </td>
                    <td data-label="Created At">{{ slider.created_at }}</td>
                    <td data-label="Updated At">{{ slider.updated_at }}</td>
                    <td data-label="Estado">
                      <div class="custom-switch">
                        <input
                          type="checkbox"
                          :id="'switch-' + slider.id"
                          v-model="slider.status"
                          :true-value="2"
                          :false-value="1"
                          @change="() => updateSliderStatusSwitch(slider)"
                        />
                        <label :for="'switch-' + slider.id"></label>
                      </div>
                    </td>
                    <td data-label="Opciones">
                      <button class="btn btn-sm btn-outline-primary" @click="editSliders(slider)">Editar</button>
                      <button class="btn btn-sm btn-outline-danger" @click="removeSliders(slider.id)">Eliminar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DataPagination
              :current-page="currentPage"
              :last-page="lastPage"
              :has-more-pages="hasMorePages"
              @fetch-views="loadSliders"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <SliderFormModal
    v-if="showSliderForm"
    :view="view"
    :header="selectedSlider"
    @close="closeSliderForm"
    @slider-created="loadSliders"
    @success="handleSuccess"
    @error="handleError"
  />
</template>

<script>
import { reactive, ref, onMounted, watch } from 'vue';
import { Modal } from 'bootstrap';
import ModalHeader from '@/components/shared/ModalHeader.vue';
import SliderFormModal from './SliderFormModal.vue';
import DataPagination from '@/components/data-display/DataPagination.vue';
import SearchInput from '@/components/data-display/SearchInput.vue';
import userConfirmation from "@/utils/userConfirmation.js";
import { fetchSliders, deleteSlider, updateSliderStatus } from '@/services/api/sliderService.js';
import { usePagination } from '@/composables/usePagination';
import { useNotification } from '@/utils/notificationUtils';
import { updateStatus } from '@/utils/elements/statusUpdater.js';

const SLIDER_MODAL_ID = 'sliderModal';

export default {
  components: {
    ModalHeader,
    SliderFormModal,
    DataPagination,
    SearchInput,
  },
  props: {
    view: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const sliders = reactive([]);
    const loading = ref(false);
    const showSliderForm = ref(false);
    const selectedSlider = ref(null);
    const searchTerm = ref('');

    const { currentPage, lastPage, hasMorePages } = usePagination();

    const { handleSuccess, handleError } = useNotification();



    const loadSliders = async (page = 1) => {
      loading.value = true;
      try {
        const response = await fetchSliders(
          props.view.id,
          page,
          10,
          searchTerm.value
        );
        sliders.splice(0, sliders.length, ...response.data);
        currentPage.value = response.meta.current_page || 1;
        lastPage.value = response.meta.last_page || 1;
        hasMorePages.value = currentPage.value < lastPage.value;
      } catch (error) {
        console.error("Error fetching cards:", error);
      } finally {
        loading.value = false;
      }
    };

    watch(searchTerm, () => {
      loadSliders(1);
    });

    const openNewSliderForm = () => {
      selectedSlider.value = null;
      showSliderForm.value = true;
    };

    const closeSliderForm = () => {
      showSliderForm.value = false;
    };

    const closeModal = () => {
      emit('close');
    };

    const editSliders = (slider) => {
      selectedSlider.value = slider;
      showSliderForm.value = true;
    };

    const removeSliders = async (headerId) => {
      const confirmed = await userConfirmation("Esta seguro de eliminar el Slider seleccionado");
      if (!confirmed) return;
      try {
        await deleteSlider(headerId);
        loadSliders(currentPage.value);
      } catch (error) {
        console.error('Error eliminando slider:', error);
      }
    };

    const updateSliderStatusSwitch = (slider) => {
      updateStatus(slider, updateSliderStatus, 'slider');
    };

    onMounted(async () => {
      loadSliders();
      const modalElement = document.getElementById(SLIDER_MODAL_ID);
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    });

    return {
      sliders,
      loading,
      showSliderForm,
      selectedSlider,
      searchTerm,
      currentPage,
      lastPage,
      hasMorePages,
      loadSliders,
      removeSliders,
      openNewSliderForm,
      closeSliderForm,
      closeModal,
      editSliders,
      handleSuccess,
      handleError,
      updateSliderStatusSwitch,
    };
  },
};
</script>

<style scoped>
.modal-content {
  padding: 20px;
  background-color: #f9f9f9;
}

.modal-custom-width {
  max-width: 85%;
}

.img-thumbnail {
  object-fit: cover;
}

.custom-switch {
  display: inline-block;
  width: 43px;
  height: 28px;
  position: relative;
}

.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-switch label:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  left: 1px;
  bottom: 1px;
  transition: transform 0.3s;
}

.custom-switch input:checked + label {
  background-color: #4caf50;
}

.custom-switch input:checked + label:before {
  transform: translateX(20px);
}

.table-responsive-stack {
  width: 100%;
  table-layout: fixed;
}

.table-responsive-stack th,
.table-responsive-stack td {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .table-responsive-stack tr {
    display: block;
    border-bottom: 3px solid #ccc;
  }

  .table-responsive-stack th {
    display: none;
  }

  .table-responsive-stack td {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    padding-left: 50%;
    box-sizing: border-box;
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
  }

  .modal-content {
    width: 100%;
  }

  .modal-custom-width {
    max-width: 100%;
  }
}

.table-responsive {
  padding-bottom: 20px;
}

tbody {
  width: 100%;
}
</style>
