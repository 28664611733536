import {
  getConcessionaireTypeLabel,
  getConcessionaireTypeValue,
} from "@/constants/concessionaireTypes.js";

export const sidebarMenuItemsService = [
  {
    icon: "fas fa-home",
    link: "/",
    label: "Home",
  },
  {
    icon: "fas fa-dollar-sign",
    link: "/main/precios",
    label: "Precios",
    subMenu: [
      {
        icon: 'fas fa-car',
        label: 'Autos',
        link: '/main/precios/autos',
        value: 1,
      },
      {
        icon: 'fas fa-truck',
        label: 'Camiones',
        link: '/main/precios/camiones',
        value: 3,
      },
      {
        icon: 'fas fa-tractor',
        label: 'Maquinaria Agricola',
        link: '/main/precios/maquinaria',
        value: 4,
      },
      {
        icon: 'fas fa-motorcycle',
        label: 'Motos',
        link: '/main/precios/motos',
        value: 2,
      },
    ],
  },
  /*     {
        icon: "fa fa-address-card",
        link: "/main/novedades",
        label: "Novedades",
        type: 2,
      }, */
  /*      {
        icon: "fas fa-chart-line",
        link: "/main/indices",
        label: "Indices de Precio",
        type: 1,
      },  */
  {
    icon: "fas fa-users-cog",
    link: "/main/director",
    label: "Comisión Directiva",
    subMenu: [
      {
        icon: 'fas fa-user-tie',
        label: 'Directivo',
        link: '/main/director/directivo',
        value: 1,
      },
      {
        icon: 'fas fa-user-alt',
        label: 'Gerencia',
        link: '/main/director/gerencia',
        value: 2,
      },
      {
        icon: 'fas fa-user-cog',
        label: 'Operativo',
        link: '/main/director/operativo',
        value: 3,
      },
    ],


  },
  {
    icon: "fas fa-file-alt",
    link: "/main/vista",
    label: "Vista",
    type: 1,
  },
  {
    icon: "fas fa-user-shield",
    link: "/main/administrador",
    label: "Administrador",
    subMenu: [
      {
        icon: "fas fa-users",
        label: "Usuarios",
        link: "/main/administrador/usuarios",
        subMenu: [
          {
            icon: "fas fa-file-signature",
            label: "Solicitudes",
            link: "/main/administrador/solicitudes-acceso",
            value: 1,
          },
          {
            icon: "fas fa-user",
            label: "Listado",
            link: "/main/administrador/usuarios",
            value: 2,
          },
          {
            icon: "fas fa-key",
            label: "Permisos",
            link: "/main/administrador/permisos-usuarios",
            value: 1,
          },
        ],
      },
      {
        icon: 'fas fa-user-friends',
        label: 'Habitualistas',
        link: '/main/administrador/habitualist',
        value: 3,
      },
      {
        icon: "fas fa-building",
        label: "Concesionarios",
        link: "/main/administrador/concessionaires",
        value: 4,
        subMenu: [
          {
            icon: "fas fa-home",
            label: getConcessionaireTypeLabel(1),
            link: "/main/administrador/concessionaires/casa-central",
            value: getConcessionaireTypeValue("casa-central"),
          },
          {
            icon: "fas fa-store",
            label: getConcessionaireTypeLabel(2),
            link: "/main/administrador/concessionaires/sucursal",
            value: getConcessionaireTypeValue("sucursal"),
          },
        ],
      },

      {
        icon: 'fas fa-cogs',
        label: 'Ajustes',
        link: '/main/administrador/ajustes',
        subMenu: [
          {
            icon: 'fas fa-map-marker-alt',
            label: 'Provincias',
            link: '/main/administrador/ajustes/provincias',
            value: 5,
          },
          {
            icon: 'fas fa-globe-americas',
            label: 'Regionales',
            link: '/main/administrador/ajustes/regionales',
            value: 6,
          },
          {
            icon: 'fas fa-layer-group',
            label: 'Areas',
            link: '/main/administrador/ajustes/areas',
            value: 7,
          },
          {
            icon: 'fas fa-briefcase',
            label: 'Cargos',
            link: '/main/administrador/ajustes/cargos',
            value: 8,
          },

        ],
      },

    /*   {
        icon: "fas fa-envelope",
        label: "Mensajes",
        link: "/main/administrador/contact",
        value: 1,
      }, */
    ],
  },
];
